<template>
  <div style="background-color: #fff; height: 100%; width: 100%">
    <div id="baiduMap" style="width: 100%; height: 500px"></div>
    <div style="display: flex; justify-content: center; margin-top: 16px">
      <el-button type="primary" size="small" @click="handleConfirm"
        >确定</el-button
      >
      <el-button size="small" @click="goBack">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaiduMap",
  data() {
    return {
      stationId: this.$route.query.stationId,
      marker: "",
      lonLat: "",
    };
  },
  methods: {
    handleConfirm() {
      console.log(this.marker);
      this.$http("api1", "/api/asset/ChargeStation/update", "post", {
        id: this.stationId,
        lonLat: this.marker.latLng.lng + "," + this.marker.latLng.lat,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        }
        this.goBack();
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // 初始化地图
    var map = new BMapGL.Map("baiduMap");
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
    map.addControl(scaleCtrl);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);
    var cityControl = new BMapGL.CityListControl({
      // 控件的停靠位置（可选，默认左上角）
      anchor: BMAP_ANCHOR_TOP_LEFT,
      // 控件基于停靠位置的偏移量（可选）
      offset: new BMapGL.Size(10, 5),
    });
    // 将控件添加到地图上
    map.addControl(cityControl);
    map.centerAndZoom("济源市", 13);
    this.$http(
      "api1",
      "/api/asset/ChargeStation/info/" + this.stationId,
      "get"
    ).then((res) => {
      this.lonLat = res.data.data.lonLat;
      if (this.lonLat) {
        console.log(this.lonLat);
        var arr = this.lonLat.split(",");
        var point = new BMapGL.Point(arr[0], arr[1]);
        map.centerAndZoom(point, 15);
        this.marker = new BMapGL.Marker(point);
        map.addOverlay(this.marker);
      } else {
        setTimeout(() => {
          var cen = map.getCenter();
          console.log(cen);
          this.marker = new BMapGL.Marker(new BMapGL.Point(cen.lng, cen.lat));
          map.addOverlay(this.marker);
        }, 2000);
      }
    });
    var that = this;
    map.addEventListener("click", function (e) {
      console.log(this);
      console.log(that);
      map.clearOverlays();
      that.marker = new BMapGL.Marker(
        new BMapGL.Point(e.latlng.lng, e.latlng.lat)
      );
      map.addOverlay(that.marker);
    });
    // 其他地图操作...
  },
  // ...
};
</script>
